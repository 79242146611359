import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Table,Grid,Dropdown,Button,Step,Icon,Image,Menu,Pagination,Dimmer,Loader,Checkbox,Header,Message,Breadcrumb, Segment} from 'semantic-ui-react'
import SideLeftUncoverSales from './SidebarSales';
import {paymentActions,alertActions, saleActions,recipesActions} from '../_actions';
import {history,toaster_dispatch, trim,isFloat,isNumeric,isEmpty,csv_sells_columns} from '../_helpers';
import { VATValues } from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import Papa from 'papaparse';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'
//import img_register from '../Assets/register.png'
import Dropzone from 'react-dropzone';


const options = [
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]
const CSV_MIN_COLUMNS = 3
const CSV_MAX_COLUMNS = 7
const CSV_MAX_ROWS_LIMIT_PREMIUM = 300
const CSV_MAX_ROWS_LIMIT_NOT_PREMIUM = 5

let title = <FormattedMessage id="importsales.file.error.title"
                            defaultMessage="Upload File Error"/>
let content_err_cols_size = <FormattedMessage id="importsales.file.colminmax.error.content"
                                              defaultMessage="File columns number invalid - Change File/Use template to continue"/>
let content_err_cols_mandatory = <FormattedMessage id="importsales.file.colmandatory.error.content"
                                            defaultMessage="Columns Name, Price, Quantity, Unit Price should be set (no duplicates)"/>
let content_err_cols_names = <FormattedMessage id="importsales.file.colnames.error.content"
                                            defaultMessage="Some field(s) from column Name are invalid"/>
let content_err_cols_prices = <FormattedMessage id="importsales.file.colprices.error.content"
                                              defaultMessage="Some field(s) from column Price should be numeric"/>
let content_err_cols_quantities = <FormattedMessage id="importsales.file.colquantities.error.content"
                                                   defaultMessage="Some field(s) from column Quantity are are not filled or invalid"/>
let content_err_cols_units = <FormattedMessage id="importsales.file.colunits.error.content"
                                              defaultMessage="Some field(s) from column Unit Price are not filled or invalid"/>
     


function isValidDate(stringDate,locale) {
    if (locale == 'fr'){
         //FR :  DD/MM/YYY  DD-MM-YYY
        //const regex =/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
        const regex =/^([0-2][0-9]|(3)[0-1])[\/|-](((0)[0-9])|((1)[0-2]))[\/|-]\d{4}$/;
        //console.log(stringDate)
        return regex.test(stringDate);
    }else{
        //US :  MM/DD/YYY  MM-DD-YYY  YYYY/MM/DD YYYY-MM-DD
        return !isNaN(Date.parse(stringDate));
    }
}
 
class ImportSales extends Component {
    constructor(props) {
      super(props);
      this.state = {
        csvfile: undefined,
        rejectedfile: undefined,
        listdata: null,
        isWithCol: true,
        isWithStock: true,
        cols: [],
        //submitted: false,
        page: 1,
        itemsPerPage: 20,
        stepIndex: 1,
        verified: false,

        optionsCategories : [
            { key: 'not used',
              text: props.intl.formatMessage(csv_sells_columns.not_used),
              value: 'not used',
              color: "red",
            },
            { key: 'name',
              text:  props.intl.formatMessage(csv_sells_columns.name),
              value: 'name',
              color: "blue",
            },
            { key: 'internal code',
                text: props.intl.formatMessage(csv_sells_columns.internal_code),
                value: 'internal code',
                color: "blue",
            },
            { key: 'quantity',  
                text:  props.intl.formatMessage(csv_sells_columns.quantity),
                value: 'quantity',
                color: "blue",
              },
            { key: 'sells',
              text:  props.intl.formatMessage(csv_sells_columns.sells),
              value: 'sells',
              color: "blue",
            },
            { key: 'pu',
              text:  props.intl.formatMessage(csv_sells_columns.pu),
              value: 'pu',
              color: "blue",
            },
            { key: 'tax',
              text: props.intl.formatMessage(csv_sells_columns.tax),
              value: 'tax',
              color: "blue",
            }
            ],

        DEFAULT_COLUMNS : [
            props.intl.formatMessage(csv_sells_columns.name),
            props.intl.formatMessage(csv_sells_columns.internal_code),
            props.intl.formatMessage(csv_sells_columns.quantity),
            props.intl.formatMessage(csv_sells_columns.sells),
            props.intl.formatMessage(csv_sells_columns.pu),
            props.intl.formatMessage(csv_sells_columns.tax)],

         COLUMN_NA_NAME : props.intl.formatMessage(csv_sells_columns.not_used),
      };
      this.updateData = this.updateData.bind(this);
      this.setPageNum = this.setPageNum.bind(this);
      this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
      props.dispatch(paymentActions.getSubscriptionState());
      props.dispatch(paymentActions.getCustomerMembership());
      props.dispatch(recipesActions.getAll(true));
    }
  
    handleChange = event => {
      this.setState({csvfile: event.target.files[0]});
    };
  
    importCSV = () => {
      const { csvfile } = this.state;
      //const { dispatch } = this.props;
      if (csvfile) {
        Papa.parse(csvfile, {
                    complete: this.updateData,
                    skipEmptyLines : true,
                    comments: "#",
                    header: false,
                });
      }/* else{
        dispatch(alertActions.warn({title: 'File missing',
                                    content:'Select your file to proceed',
                                    stringMode: true }));
      } */
    };
  
    updateData(result) {
      const {isWithCol,COLUMN_NA_NAME} = this.state;
      const { dispatch,recipes } = this.props;
      let emptyRows = []

      result.data.forEach(row => {
        var allFieldsEmpty = true;
        for (let prop in row) {
          // Trim spaces from front and back 
          row[prop] = row[prop].trim();
          if (row[prop].length > 0)
          {
              allFieldsEmpty = false;
              break;
          }
        }
        if (allFieldsEmpty) emptyRows.push(result.data.indexOf(row));
      });

      var data = result.data.filter( (_,index) => !emptyRows.includes(index));

      if (recipes && recipes.items){
        let recipe_names = [];
        recipes.items.map((recipe,index) => {
            recipe_names.push(recipe.name.toLowerCase());
        });
        let dataOnly = data.slice(1).filter(item => recipe_names.includes(item[0].toLowerCase()))
        data = [ data[0],...dataOnly]
      }
      this.setState({ listdata: data});
      console.log(data)
      let cols={}

      if (isWithCol){
        data[0].map((i,index) => {
/*             if (i.charAt(i.length - 1) === 's') {
                i = i.substr(0, i.length - 1);
            } */
            cols[index] = i.normalize('NFKD').toLowerCase().replace (/[\u0300-\u036F]/g, "")
        })
           
      }else{
        data[0].map((i,index) => {cols[index] = COLUMN_NA_NAME})
      }

      if (Object.keys(cols).length < CSV_MIN_COLUMNS || Object.keys(cols).length > CSV_MAX_COLUMNS){
          //console.log("Not supported - columns number invalid (use template)")
          this.setState({csvfile : undefined});

          dispatch(alertActions.error({title: 'importsales.file.error.title',
                                      content:"importsales.file.colminmax.error.content"}));
      }else{
        this.setState({stepIndex:2});
        this.setState({cols : cols});
        console.log(cols)
      }
    }

    getValueByNameColumns(name){
        const {optionsCategories} = this.state;
        let found = optionsCategories.find(element => element.text == name)
        if (found)
            return found.value
        else 
            return undefined
    }

    getNameByValueColumns(value){
        const {optionsCategories} = this.state;
        let found = optionsCategories.find(element => element.value  == value)
        if (found)
            return found.text
        else 
            return undefined
    }

    changeColumnTitle(event,data,index){
        event.preventDefault();
        const {cols} = this.state;
        cols[index] = this.getNameByValueColumns(data.value)
        this.setState({cols : cols});
        //console.log(cols)
    }

    addColumns(){
        const {cols,verified} = this.state;
        const {optionsCategories,DEFAULT_COLUMNS,COLUMN_NA_NAME} = this.state;
        if (cols){
            return Object.keys(cols).map((key,index) => {
               if (index <= CSV_MAX_COLUMNS){
                    if (!verified){
                        if (!DEFAULT_COLUMNS.includes(cols[key])){
                            cols[key] = COLUMN_NA_NAME
                        }
                        
                        return (
                            <Table.HeaderCell>
                                <Dropdown compact
                                    placeholder='Select'
                                    fluid
                                    selection
                                    value = {this.getValueByNameColumns(cols[key])}
                                    defaultValue= {this.getValueByNameColumns(COLUMN_NA_NAME)}
                                    options={optionsCategories}
                                    onChange={(e,data) => this.changeColumnTitle(e,data,key)} />
                            </Table.HeaderCell>
                        );

                    }else{//verified = true
                        if (cols[key] != COLUMN_NA_NAME ){
                            return (
                                <Table.HeaderCell>
                                    {cols[key]}
                                </Table.HeaderCell>
                            );
                        }
                    }
                }
              });
        }
    }

    addCells(row){
        const {cols,COLUMN_NA_NAME,verified} = this.state;
        return row.map((cell,idx) => {
            if (idx <= CSV_MAX_COLUMNS){
                if (!verified){
                    return (
                        <Table.Cell>
                            {cell}     
                        </Table.Cell>
                    );
                }else{// verified = true
                    if (cols[idx] != COLUMN_NA_NAME ){
                        return (
                            <Table.Cell>
                                {cell}     
                            </Table.Cell>
                        );
                    }
                }
            }
        });
    }

    addRows(items){
        const {isWithCol,page} = this.state;
        let startIndex = 0
        if (isWithCol) startIndex = 1

        if (items.length < 2){
            return (<Table.Row >
                        <Message icon style={{marginLeft:'2em',marginTop:'3em'}} /* style={styles.FontLato12} */>
                            <Icon name='redo' size='small'/>
                            <Message.Content>
                                 <Message.Header>
                                    <FormattedMessage id="importsales.check.result.error.header"
                                                       defaultMessage="Oops something happened"/>
                                </Message.Header>
                                 <Message.List>
                                     <Message.Item>
                                        <FormattedMessage id="importsales.check.result.error.message"
                                                           defaultMessage="No element created (no recipe corresponds to existing)."/>
                                    </Message.Item>
                                    <Message.Item>
                                         <FormattedMessage id="importsales.check2.result.error.message"
                                                           defaultMessage="Check your file and retry."/>
                                    </Message.Item>
                                </Message.List>
                            </Message.Content>
                        </Message>
            </Table.Row>);
        }

        return items.map((row,index) => {
             if (page == 1){
                if (index >= startIndex){
                    return (<Table.Row key={index}>
                        {this.addCells(row)}
                    </Table.Row>);
                }
             }else {
                return (<Table.Row key={index}>
                    {this.addCells(row)}
                </Table.Row>);
             }
        });
    }

    getColumnsIndex(name){
        const {cols} = this.state;
        let index = -1;
        index = Object.keys(cols).find(key => cols[key] === name.toLowerCase());
        return index
    }


    assertAllNameFields(){
        let values = [];
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1

        let name_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.name))
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                if (row &&  (row[name_index] != "")
                    && (!isEmpty(row[name_index]))
                    &&(trim(row[name_index]).length>0)){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }         
        });
        return values.every(v => v===true)
    }

    assertAllPriceFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let sells_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.sells))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                
                if (row && (row[sells_index]) != ""){
                    let price = trim(row[sells_index]).replace(/,/g, '.')
                    if (!isFloat(price) && !isNumeric(price)){
                        values.push(false)
                    }
                }else{
                    values.push(true)
                }

/*                 let price = trim(row[sells_index]).replace(/,/g, '.')
                if ((isFloat(price) || isNumeric(price))
                    && (row[sells_index] != "")
                    && (!isEmpty(row[sells_index]))
                    && Math.trunc(row[sells_index]).toString().length<7){
                        values.push(true)
                }else{
                    values.push(false)
                } */
            }       
        });
        return values.every(v => v===true)
    }

    assertAllPriceUnitFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let priceunit_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.pu))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                if (row && (row[priceunit_index]) != ""){
                    let price = trim(row[priceunit_index]).replace(/,/g, '.')
                    if (!isFloat(price) && !isNumeric(price)){
                        values.push(false)
                    }
                }else{
                    values.push(true)
                }
            }       
        });
        return values.every(v => v===true)
    }

/*     assertAlTaxFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let tax_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.tax))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                let tax = trim(row[tax_index]).replace(/,/g, '.')
                if ((isFloat(tax) || isNumeric(tax))
                    //&& (row[tax_index] != "")
                    //&& (!isEmpty(row[tax_index]))
                    && Math.trunc(row[tax_index]).toString().length<4){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }       
        });
        return values.every(v => v===true)
    } */

    assertAllQuantityFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let quantity_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.quantity))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                let quantity = trim(row[quantity_index]).replace(/,/g, '.')
                if ((isFloat(quantity) || isNumeric(quantity))
                    && (row[quantity_index] != "")
                    && (!isEmpty(row[quantity_index]))
                    && Math.trunc(row[quantity_index]).toString().length<10){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }       
        });
        return values.every(v => v===true)
    }

    checkUniqueColumns(){
        const {cols,DEFAULT_COLUMNS} = this.state;
        var tmp = {};
        let count = 0;
        for(let i in cols){
            var val = cols[i];
            if (DEFAULT_COLUMNS.includes(val)){
                // Have we seen this value before?
                if(!tmp.hasOwnProperty(val)){
                    // Mark the value as seen for the first time
                    tmp[val] = []; // 0 duplicates
                }
                else{
                    // We've seen it before, save the duplicate key
                    tmp[val].push(i);
                    count+=1
                }
            }
        }
        if (count > 0){
            return false
        }
        return true
    }

    assertColumnsValidity(){
        const {cols} = this.state;
        const {intl} = this.props;
        //Assert mandatory fields are unique
        if (!this.checkUniqueColumns()){
            return false
        }
         //Assert columns mandatory are filled
        if (!this.getColumnsIndex(intl.formatMessage(csv_sells_columns.name)) || 
            !this.getColumnsIndex(intl.formatMessage(csv_sells_columns.sells)) ||
            !this.getColumnsIndex(intl.formatMessage(csv_sells_columns.quantity)) ||
            !this.getColumnsIndex(intl.formatMessage(csv_sells_columns.pu)))
            return false

         //Assert columns at least min is filled
        if (Object.keys(cols).length<CSV_MIN_COLUMNS){
            return false
        }
        return true
    }

    importSales(e){
        e.preventDefault();
        const {listdata,isWithCol,isWithStock} = this.state;
        const {dispatch,intl } = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1

        let name_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.name))
        let sells_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.sells))
        let quantity_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.quantity))
        let pu_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.pu))
        let internal_code_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.internal_code))
        let tax_index = this.getColumnsIndex(intl.formatMessage(csv_sells_columns.tax))

        if (!this.assertColumnsValidity()){
            dispatch(alertActions.error({title: 'importsales.file.error.title',
                                        content:'importsales.file.colmandatory.error.content'}));
            //console.log('columns mandatory incorrect => return')
            return;
        }

        if (!this.assertAllNameFields()){
            dispatch(alertActions.error({title: 'importsales.file.error.title',
                                        content:'importsales.file.colnames.error.content'}));
            return;
        }
    
        if (!this.assertAllPriceFields()){
            dispatch(alertActions.error({title: 'importsales.file.error.title',
                                        content:'importsales.file.colprices.error.content' }));
            //console.log('prices incorrect => return')
            return;
        }

        if (!this.assertAllPriceUnitFields()){
            dispatch(alertActions.error({title: 'importsales.file.error.title',
                                        content:'importsales.file.colunits.error.content' }));
            //console.log('prices incorrect => return')
            return;
        }

/*         if (!this.assertAlTaxFields()){
            dispatch(alertActions.error({title: 'importsales.file.error.title',
                                        content:'importsales.file.coltax.error.content'}));
            return;
        } */

        if (!this.assertAllQuantityFields()){
            dispatch(alertActions.error({title: 'importsales.file.error.title',
                                         content:'importsales.file.colquantities.error.content' }));
            //console.log('quantity incorrect => return')
            return;
        }
      

        let sellsData = []
        //this.setState({ submitted: true });
        if (this.isPremiumUser()){
            sellsData = listdata.slice(startIndex,startIndex + CSV_MAX_ROWS_LIMIT_PREMIUM)
        }else{
            sellsData = listdata.slice(startIndex,startIndex + CSV_MAX_ROWS_LIMIT_NOT_PREMIUM)
        }

        let updates = []

        if (sellsData && sellsData.length>0) {
    
            sellsData.map((sell, idx) => {
              let quantity_floor = Math.floor( Number(trim(sell[quantity_index]).replace(/,/g, '.')))
              let sell_element = {
                sale_date : new Date(),
                recipe_name : trim(sell[name_index]),
                quantity : quantity_floor.toFixed(3),
                //price : Number(trim(sell[sells_index]).replace(/,/g, '.')).toFixed(3),
                //price_unit : Number(trim(sell[pu_index]).replace(/,/g, '.')).toFixed(3),
              }

              if (sells_index){
                if (sell[sells_index]!=""){
                    Object.assign(sell_element,{ price :  Number(trim(sell[sells_index]).replace(/,/g, '.')).toFixed(3) });
                }
              }

              if (pu_index){
                if (sell[pu_index]!=""){
                    Object.assign(sell_element,{ price_unit :  Number(trim(sell[pu_index]).replace(/,/g, '.')).toFixed(3) });
                }
              }

              if (internal_code_index){
                if (sell[internal_code_index]!=""){
                    Object.assign(sell_element,{ recipe_ref :  trim(sell[internal_code_index]) });
                }
              }

              if (tax_index){
                let str_tax = trim(sell[tax_index]).replace(/,/g, '.')
                if ((str_tax!="") && 
                    (isFloat(str_tax) || isNumeric(str_tax)) && 
                    (Math.trunc(str_tax).toString().length<3))
                {
                    let tax_value = Number(str_tax)
                    let tax_allowed = VATValues.reduce((prev, curr) => Math.abs(curr - tax_value) < Math.abs(prev - tax_value) ? curr : prev)

                    Object.assign(sell_element,{ vat : Number(Number(tax_allowed)/100).toFixed(3) });   
                }
              }
              updates.push(sell_element)
            });    
            this.setState({stepIndex:4});
            dispatch(saleActions.addFromFile(updates,isWithStock));
        }
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    onDropAccepted(acceptedFiles) {
        //console.log(acceptedFiles[0])
        this.setState({csvfile:acceptedFiles[0]});
    }

    onDropRejected(rejectedFiles) {
        const {dispatch} = this.props;
        this.setState({rejectedFile:rejectedFiles[0]});

        dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                    content:'importingredients.file.size.error.content'}));
    }

    onDeleteFile(e) {
        e.stopPropagation();
        this.setState({csvfile:undefined});
    }

    onSelect = (e,index) => {
        e.preventDefault();
        const { dispatch} = this.props;
       
        this.setState({stepIndex:index});

        if (index < 3){
            this.setState({verified:false});
        }else{
            this.setState({verified:true});
        }
    }

    onValidateStep(e){
        e.preventDefault();
        const { dispatch} = this.props;

        //Check at least one mandatory columns are set
        if (!this.assertColumnsValidity()){
                dispatch(alertActions.error({title: 'importsales.file.error.title',
                                            content:'importsales.file.colmandatory.error.content'}));
                //console.log('columns mandatory incorrect => return')
                return;
        }    
        this.setState({stepIndex:3});
        this.setState({verified:true});
    } 

    onCheckboColumnChange = (e, data) => {
        this.setState({ isWithCol: data.checked});
    }

    onCheckboxInventoryChange = (e, data) => {
        this.setState({ isWithStock: data.checked});
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: true}})
    }

    isPremiumUser(){
        const { subscription,customer } = this.props;
        let user = null;

        if (customer){
            user = customer.user;
            //console.log(user);
        } 

        if (user && user.bypass === true){
            return true
        } 

        if ((subscription && !isEmpty(subscription) && (subscription.active === true)) || (subscription.bypass === true)){
            return true
        }
        return false
    }

    render() {
      const {listdata,page,itemsPerPage,stepIndex,csvfile,isWithCol,isWithStock,cols} = this.state;
      const { alert,dispatch,intl,sales,adding} = this.props;
      let totalPages = 1;
      let items = [];
      let length = 0;
      let begin = 0;
      let end  = 0;

      if (listdata && listdata.length>0 ){
          length = listdata.length
          totalPages = Math.ceil( length / itemsPerPage);
          begin = (page - 1) * itemsPerPage;
          end = (page - 1) * itemsPerPage + itemsPerPage;
          if (end > length) end=length;
          items = listdata.slice(begin,end);
          console.log(items)
      }

      let fileName = ''
      if (csvfile){
        fileName = csvfile.name
      }

      let content = csvfile 
      ? ( <span  style={{'font-family': 'Lato','font-size': '16px'}} color='blue'>{fileName} 
                <Icon name='trash alternate outline' color='blue' style={{'cursor': 'pointer'}} onClick={(event)=>this.onDeleteFile(event)}/>
          </span>) 
      : ( <span style={{'font-family': 'Lato','font-size': '16px'}}>
            <FormattedMessage id="importsales.dropzone.text.line1"
                              defaultMessage="Drag 'n' drop your CSV file here, or"/>{' '}
                    <a href="#"><FormattedMessage id="importsales.dropzone.text.line2"
                                                  defaultMessage="Click here to select"/></a></span> );

      return (
        <SideLeftUncoverSales>
            <div className="App">
{/*                 <h2><FormattedMessage id="importingredients.page.title"
                                      defaultMessage="Import your ingredients from a CSV file from Excel"/></h2>
                <br/> <br/> */}
                <Grid stackable style={styles.FontLato13Border} >
                   <Grid.Row columns={1}>
                     <Breadcrumb  style={{background:'#fcfcfc'}}>
                            <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="importsales.link.dashboard"
                                                                        defaultMessage='Dashboard'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/sales/'}><FormattedMessage id="importsales.link.sales"
                                                                        defaultMessage='Sales'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section active><FormattedMessage id="importsales.link.import"
                                                                        defaultMessage='Import'/></Breadcrumb.Section>
                     </Breadcrumb>
                    </Grid.Row>
                    <Grid.Row columns={1} >
                        <Grid.Column width={10}>
                            <Header as='h2' /* color='blue' */>
                                <Header.Content>
                                    <FormattedMessage id="importsales.page.title"
                                      defaultMessage="Import your sells from a CSV file (Excel)"/>
                                </Header.Content>
                            </Header>
                            </Grid.Column>
                    </Grid.Row>
                    <br/>
                    <Grid.Row columns={1} /*style={{'overflow-x': 'auto'}}*/>
                        <Grid.Column /*width={14}*/  mobile={16} tablet={16} computer={14}> 
                            <Step.Group fluid stackable widths={4}>
                                <Step active={true}  disabled={stepIndex!=1} /*disabled={stepIndex==2 || stepIndex ==3} link={stepIndex==4} href='/importingredients'*/>
                                    <Icon name='cloud upload' color='blue'/>
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importsales.step1.title"
                                                                     defaultMessage="Load your sells"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importsales.step1.subtitle"
                                                                            defaultMessage="file in CSV format"/>
                                      </Step.Description>
                                    </Step.Content>
                                </Step>
                                <Step active={true} disabled={stepIndex!=2}>
                                    <Icon name='table' color='yellow'/>
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importsales.step2.title"
                                                                     defaultMessage="Select your consistent columns"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importsales.step2.subtitle"
                                                                     defaultMessage="Set mandatory columns"/></Step.Description>
                                    </Step.Content>
                                </Step>
                                <Step active={true} disabled={stepIndex!=3}>
                                    <Icon name='clipboard list' color='teal'/>
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importsales.step3.title"
                                                                     defaultMessage="Check your data"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importsales.step3.subtitle"
                                                                     defaultMessage="As expected ?"/></Step.Description>
                                    </Step.Content>
                                </Step>
                                <Step active={true} disabled={stepIndex!=4}>             
                                    <Icon name='tags' />
                                    {/* <img src={img_register} style={{width:'40px'}}/>{' '}&nbsp;&nbsp; */}
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importsales.step4.title"
                                                                     defaultMessage="Result"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importsales.step4.subtitle"
                                                                     defaultMessage="Import status"/></Step.Description>
                                    </Step.Content>
                                </Step>
                            </Step.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} style={{'padding': '0 0 0 0'}}/>
                    {stepIndex == 1 && <Grid.Row columns={1}>
                        <Grid.Column textAlign='left' mobile={8} tablet={8} computer={12}  style={{'paddingLeft': '4.5em'}}>
{/*                             <Message fluid  warning icon >
                                    <Icon name='attention' size='large'/>
                                    <Message.Content>
                                        <Message.Header><FormattedMessage id="importsales.step1.message.header"
                                                                defaultMessage="Here you can only do your initials ingredients import or add new items."/>
                                                    
                                        </Message.Header>
                                                <p style={{'font-weight':'bold'}} > <FormattedMessage id="importsales.step1.message.subheader"
                                                                defaultMessage="If you need to update existing ingredients, please use side menu"/>
                                                                <Lin to={process.env.PUBLIC_URL + '/updateingredients'}>{' '}
                                                                    <FormattedMessage id="importsales.step1.message.subheader.link"
                                                                                    defaultMessage="Bulk Update"/>
                                                                </Link>
                                                </p>
                                    </Message.Content> 
                            </Message> */}
                            <Header size='medium'>
                                  <Icon name='file excel outline'/> 
                                  <Header.Content>
                                         <FormattedMessage id="importsales.template.file.title"
                                                         defaultMessage="Use import sales template"/> {' '}
                                      <Link to={process.env.PUBLIC_URL + 'csv/'+intl.locale+'/sales_template.csv'} target="_blank" download>
                                       {' '}<FormattedMessage id="importsales.template.file.link"
                                                         defaultMessage="[ Download here ]"/></Link>
                                  </Header.Content>
                                   <p style={{marginLeft:'3em','font-size':'15px'}} > <FormattedMessage id="importsales.template.file.subtitle"
                                                         defaultMessage=" => Update recipe names to existing recipes before proceed"/></p>
                                  {/*  <br/> */}
                                  <Segment secondary color='teal' style={{width:'30em','paddingLeft': '1em','marginLeft': '1em',paddingTop:'1em'}}>
                                  <Header.Content style={{marginLeft:"0em",marginTop:"-1em", paddingTop:'0.5em'}}> 
                                    <p style={{'font-size':'14px'}}>
                                       <FormattedMessage id="importsales.template.file.column.title"
                                                     defaultMessage='Input File format'/>
                                        </p>
                                    <div  style={{margin:'0.2em 0em 0em 0em'}} >
                                    <FormattedMessage id="importsales.template.file.column.option"
                                                     defaultMessage='First line is column names'>
                                        { label => 
                                            <Checkbox label={label} color='blue'
                                                    style={{margin:'-1em 0em 0em 1.2em',paddingTop:'-2em'}} 
                                                    onChange={this.onCheckboColumnChange}
                                                    checked={isWithCol}/>
                                        }
                                    </FormattedMessage>
                                  </div>
                                  </Header.Content>
                                  {/* <br/> */}
                                  <Header.Content  style={{marginLeft:"0em", paddingTop:'0.5em'}}> 
                                    <p style={{'font-size':'14px'}}>
                                       <FormattedMessage id="importsales.template.stock.title"
                                                     defaultMessage='Inventory update'/>
                                        </p>
                                    <div  style={{margin:'0.2em 0em 0em 0em'}} >
                                    <FormattedMessage id="importsales.template.stock.option"
                                                     defaultMessage='Automatically deduce stock numbers'>
                                        { label => 
                                            <Checkbox label={label} color='blue'
                                                    style={{margin:'-1em 0em 0em 1.2em',paddingTop:'-2em',marginTop:"-1em"}} 
                                                    onChange={this.onCheckboxInventoryChange}
                                                    checked={isWithStock}/>
                                        }
                                    </FormattedMessage>
                                    </div>
                                  </Header.Content>
                                  </Segment>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>}
                    {/* <Grid.Row columns={1}/> */}
                    {stepIndex == 1 && <Grid.Row columns={2}>
                        <Grid.Column textAlign='right'  mobile={16} tablet={16} computer={14}>
                             <Button color='blue' onClick={this.importCSV} disabled={!csvfile}><FormattedMessage id="importsales.step1.boutton.next"
                                                                                                                 defaultMessage="Next: Review your CSV upload"/></Button>
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 1 &&
                       <Grid.Row columns={2}>
                        <Grid.Column textAlign='center'  mobile={16} tablet={16} computer={14}> 
                            <Dropzone onDropAccepted={this.onDropAccepted.bind(this)}
                                      onDropRejected={this.onDropRejected.bind(this)}
                                      multiple = {false}
                                      maxSize={10485760} //10MB
                                      accept=".csv"//, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      >
                                {({getRootProps, getInputProps}) => (
                                       <section {...getRootProps()}
                                                style={{   'height': '200px',
                                                           'width': '100%',
                                                           'backgroundColor': '#f5f5f5',
                                                           'overflow': 'auto',
                                                           'text-align' : 'center'}}>
                                           <div  style={{ 'margin': '80px'}}>
                                               <input {...getInputProps()}/>
                                               <Icon name='file alternate outline' size='big'/>
                                               {content}
                                        </div>
                                    </section>)}
                            </Dropzone>
                        </Grid.Column>
                    </Grid.Row> }
                    {(stepIndex == 2 || stepIndex == 3) && 
                      <Grid.Row columns={1}>
                            <Grid.Column width={6}> 
                                <Message fluid /*style={styles.FontLato13Bold}*/ warning icon>
                                     <Icon name='info circle'/>
                                     <Message.Content>
                                    <Message.Header><FormattedMessage id="importsales.step2.message.header"
                                                              defaultMessage="Select the columns to [consider] or [ignore]"/>
                                    </Message.Header>
                                    <Message.List>
                                        <Message.Item>
                                            <FormattedMessage id="importsales.step2.message.mandatory"
                                                              defaultMessage="Mandatory: Recipe name, Quantity. "/>
                                        </Message.Item>
                                        <Message.Item>
                                            <FormattedMessage id="importsales.step2.message.optional"
                                                              defaultMessage="Optional: Internal code, Unit price, Sells, Sales Tax."/>
                                        </Message.Item>
                                    </Message.List>
                                    </Message.Content>
                                </Message>
                          </Grid.Column>
                          <br/> <br/> 
                    </Grid.Row>}
                    {stepIndex == 2 && 
                     <Grid.Row columns={3} style={{'overflow': 'auto'}}>
                        <Grid.Column textAlign='left' mobile={8} tablet={8} computer={7}>
                             <Button color='blue' onClick={(e)=>this.onSelect(e,1)}><FormattedMessage id="importsales.step2.boutton.back"
                                                                                                       defaultMessage="Back: Change your file"/></Button>
                        </Grid.Column>
                        <Grid.Column textAlign='right' mobile={8} tablet={8} computer={7}> 
                        {items && items.length >0 &&          
                            <Button color='blue' disabled={items.length <=1 } onClick={(e)=> this.onValidateStep(e)}>
                                <FormattedMessage id="importsales.step2.boutton.next"
                                                  defaultMessage="Next: Verify the result"/></Button>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 2 && 
                    <Grid.Row columns={1} style={{'overflow': 'auto'}}>
                        <Grid.Column /*width={14}*/  mobile={16} tablet={16} computer={14} > 
                        {items && items.length >0 && cols &&         
                                    <Table selectable  
                                            unstackable 
                                            compact 
                                            striped 
                                            singleLine 
                                            style={styles.FontLato12}>
                                            <Table.Header>
                                                <Table.Row>
                                                    {this.addColumns(cols)} 
                                                </Table.Row>
                                            </Table.Header>   
                                            <Table.Body>
                                                {this.addRows(items)}
                                            </Table.Body>
                                            <br/> <br/> <br/> <br/><br/> <br/> <br/> <br/>
                                    </Table>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 2 && 
                    <Grid.Row columns={3}>
                    {items && items.length >0 && 
                            <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={20} 
                                                  options={options} 
                                                  item
                                                  inline
                                                  fluid
                                                  onChange={this.onElemPerPageChange}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="importsales.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="importsales.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>}
                        {items && items.length >0 && 
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={10}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem = {null} 
                                        lastItem = {null}
                                        onPageChange={this.setPageNum}
                                        size='small'
                                    />
                                </Grid.Column>}
                    </Grid.Row> }
                    {stepIndex == 3 && 
                     <Grid.Row columns={3} style={{'overflow': 'auto'}}>
                        <Grid.Column textAlign='left' mobile={8} tablet={8} computer={7}>
                             <Button color='blue' onClick={(e)=>this.onSelect(e,2)}><FormattedMessage id="importsales.step3.boutton.back"
                                                                                                       defaultMessage="Back: Update columns"/></Button>
                        </Grid.Column>
                        <Grid.Column textAlign='right' mobile={8} tablet={8} computer={7}> 
                        {items && items.length >0 &&   
                            <Button color='blue' disabled={items.length <=1 } onClick={(e)=> this.importSales(e)}><FormattedMessage id="importsales.step3.boutton.next"
                                                                                                            defaultMessage="Next: Add your sells"/></Button>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 3 && 
                    <Grid.Row columns={1} style={{'overflow': 'auto'}}>
                        <Grid.Column /*width={14}*/  mobile={16} tablet={16} computer={14} > 
                        {items && items.length >0 && cols &&         
                                    <Table selectable  
                                            unstackable 
                                            compact 
                                            striped 
                                            singleLine 
                                            style={styles.FontLato11} >
                                            <Table.Header>
                                                <Table.Row>
                                                    {this.addColumns(cols)} 
                                                </Table.Row>
                                            </Table.Header>   
                                            <Table.Body>
                                                {this.addRows(items)}
                                            </Table.Body>
                                    </Table>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 3 && 
                    <Grid.Row columns={3}>
                    {items && items.length >0 && 
                            <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={20} 
                                                  options={options} 
                                                  item
                                                  inline
                                                  fluid
                                                  onChange={this.onElemPerPageChange}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="importsales.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="importsales.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>}
                        {items && items.length >0 && 
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={10}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem = {null} 
                                        lastItem = {null}
                                        onPageChange={this.setPageNum}
                                        size='small'
                                    />
                                </Grid.Column>}
                    </Grid.Row> } 
                    <Grid.Row columns={1}/>  
                    {stepIndex == 4 &&  sales &&
                     <Grid.Row columns={3} >
                            <Dimmer active={adding} inverted>
                                <Loader indeterminate><FormattedMessage id="importsales.step4.result.loading.text"
                                                                        defaultMessage='Import ongoing ...'/></Loader>
                            </Dimmer>
                            <Grid.Column textAlign='left'  mobile={10} tablet={10} computer={10}>
                               {!adding && sales.items && sales.items.length > 0 &&
                                    <Message icon fluid style={styles.FontLato13Bold}>
                                        <Icon name='thumbs up outline' size='small'/>
                                        <Message.Content>
                                            <Message.Header>
                                                <FormattedMessage id="importsales.step4.result.success.header"
                                                                defaultMessage="Sales import (corresponding to existing recipes)"/>
                                            </Message.Header>
                                            <Message.List>
                                                <Message.Item>
                                                    {sales.items[0].length}&nbsp;
                                                    <FormattedMessage id="importsales.step4.result.success.status"
                                                                    defaultMessage="elements created with success (inventory stock choice applied)."/>
                                                </Message.Item>
                                                {!this.isPremiumUser() &&
                                                <Message.Item>
                                                    <FormattedMessage id="importsales.step4.result.success.premium"
                                                                    defaultMessage="You need an Entreprise plan for this feature"/>
                                                </Message.Item>}
                                            </Message.List>
                                        </Message.Content>
                                    </Message>
                               }
                              {!adding && (!sales.items || sales.items[0].length == 0 ) &&
                                <Message icon fluid style={styles.FontLato13Bold}>
                                    <Icon name='redo' size='small'/>
                                    <Message.Content>
                                        <Message.Header>
                                                <FormattedMessage id="importsales.step4.result.error.header"
                                                                defaultMessage="Oops something happened"/>
                                        </Message.Header>
                                        <Message.List>
                                                <Message.Item>
                                                    <FormattedMessage id="importsales.step4.result.error.message"
                                                        defaultMessage="No element created (check your file and retry)."/>
                                                </Message.Item>
                                                {!this.isPremiumUser() &&
                                                <Message.Item>
                                                    <FormattedMessage id="importsales.step4.result.success.premium"
                                                                    defaultMessage="You need an Entreprise plan for this feature"/>
                                                </Message.Item>}
                                        </Message.List>
                                    </Message.Content>
                               </Message>
                               }
                               {!adding && !this.isPremiumUser() &&
                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                        <Image src={img_performance} size ='small' rounded/>
                                        <br/>
                                        <Message.Content style={{marginLeft:'1em'}}>
                                            <Message.Header color='black'>
                                                                <FormattedMessage id="importsales.premium.message.upgradeplan"
                                                                                    defaultMessage='PREMIUM Feature'/>
                                            </Message.Header>
                                            <br/>
                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="importsales.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                            </Button>                                      
                                        </Message.Content>
                                    </Message>}
                            </Grid.Column>
                     </Grid.Row>}
                </Grid>
            </div>
            {alert.message && toaster_dispatch(dispatch,alert,intl)}
        </SideLeftUncoverSales>
      );
    }
  }
  


  function mapStateToProps(state) {
    const { dispatch, alert,sales,recipes } = state;
    const { subscription,customer } = state.payment;
    const { adding } = state.sales;
    return {
        sales,
        recipes,
        adding,
        subscription,
        customer,
        dispatch,
        alert,
    };
  }
  
  
  const connectedImportSales = injectIntl(connect(mapStateToProps)(ImportSales));
  export { connectedImportSales as ImportSales };


 